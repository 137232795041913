import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`我的项目`}</h2>
    <ProjectCard title="Axtn Network" link="https://www.axtn.top" bg="rgb(45,55,72)" mdxType="ProjectCard">
  Minecraft Server-我的世界小游戏服务器.
    </ProjectCard>
    <ProjectCard title="Fxidc" link="https://www.fxidc.net" bg="rgb(45,55,72)" mdxType="ProjectCard">
  绯想云计算服务商，只是一名员工QwQ.
    </ProjectCard>
    <ProjectCard title="BiliBili" link="https://space.bilibili.com/414947108" bg="rgb(45,55,72)" mdxType="ProjectCard">
  一位化身为鸽子的B站UP主，技术区.
    </ProjectCard>
    <ProjectCard title="MCHACKBBS" link="#" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  新项目，暂未开放
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      