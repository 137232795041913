import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`关于我`}</h2>
    <blockquote>
      <p parentName="blockquote">{`初三中学生，喜欢研究新项目，抑郁症患者`}</p>
    </blockquote>
    <p>{`每天过着浑浑噩噩的生活。学习一般，喜欢研究计算机，经常闲的没事做一些小项目，偶尔鸽子。`}<br />{`
这孩子，或许可以寄了`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      