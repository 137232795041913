import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`联系我`}</h2>
    <p>{`可以通过 `}<a parentName="p" {...{
        "href": "mailto:2860488343@qq.com"
      }}>{`邮箱`}</a>{` 或者 `}<a parentName="p" {...{
        "href": "http://wpa.qq.com/msgrd?v=3&uin=2860488343&site=qq&menu=yes"
      }}>{`QQ`}</a>{` & `}<a parentName="p" {...{
        "href": "https://discord.gg/7wHPAfqFfQ"
      }}>{`Discord`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      